<template>
  <div class="visibility-permission-familly">
    <div class="permission-hearder">
      <div class="title">Utilisateurs</div>
      <div class="Search-style-component" v-if="checkPermission('GPHTCU')">
        <div class="search-box">
          <font-awesome-icon icon="search" class="icon" />
          <input
            class="search-input"
            placeholder="Chercher"
            v-model="search"
            @input="handleSearch"
          />
        </div>
      </div>

      <div
        v-if="loadingFamillysPermissions"
        class="chargement-message-text ml-2"
      >
        Chargement en cours
      </div>
      <div class="fix-height-slide-user">
        <div
          v-for="user in famillysPermissions"
          :key="user.id"
          @click.prevent="handleClickUser(user)"
          class="selected-role"
        >
          <div id="info-wrap" class="interface-permission">
            <div class="description-column ">
              <span>
                {{ user.full_name }}
              </span>
            </div>
            <div class="info-column">
              <div
                class="icon-color"
                v-if="selectedUser && selectedUser.full_name == user.full_name"
              >
                <font-awesome-icon icon="check" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="permission-hearder-sous-interface" v-if="selectedUser">
      <div class="permission-user" v-if="selectedUser">
        <div class="title">Familles</div>
        <div class="list-interface">
          <div class="autorisation-permission ">
            <div class="row">
              <div class="col-1">
                <b-form-checkbox
                  name="flavour-1"
                  id="checkbox-group-1Tous"
                  class="check-th"
                  :checked="computedValueCheck(selectedUser.famillies)"
                  @change="assignTousPermission($event, selectedUser.famillies)"
                  v-if="checkPermission('GPHTAPUS')"
                >
                </b-form-checkbox>
              </div>
              <div class="col-9">Tous</div>
            </div>
          </div>
          <div
            v-for="familly in getListFamilleConfigFrias"
            :key="familly.id"
            class="selected-role"
          >
            <div class="autorisation-permission ">
              <div class="row">
                <div class="col-1">
                  <b-form-checkbox
                    name="flavour-1"
                    :id="'checkbox-group-1' + familly.id"
                    class="check-th"
                    :checked="computedValue(familly)"
                    @change="assignFamilly(familly)"
                    v-if="checkPermission('GPHTAFTP')"
                  >
                  </b-form-checkbox>
                </div>
                <div class="col-9">
                  {{ familly.familly }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      selectedUser: null,
      selectedPage: null,
      getListePermission: [],
      search: null
    };
  },
  methods: {
    ...mapActions([
      'getAllFamillePermission',
      'assignFamillyToUser',
      'removeFamillyToUser',
      'fetchAllFammilleConfig'
    ]),
    handleSearch() {
      this.handleFilter();
    },
    handleFilter() {
      this.getAllFamillePermission({ search: this.search });
    },
    assignFamilly(familly) {
      let payload = {
        user_id: this.selectedUser.id,
        familly_id: familly.id
      };
      if (this.computedValue(familly) == true) {
        this.removeFamillyToUser(payload);
      } else {
        this.assignFamillyToUser(payload);
      }
    },
    async assignTousPermission(event, famillies) {
      if (event == true) {
        for (let i = 0; i < this.getListFamilleConfigFrias.length; i++) {
          let payload = {
            user_id: this.selectedUser.id,
            familly_id: this.getListFamilleConfigFrias[i].id
          };
          await this.assignFamillyToUser(payload);
        }
      } else {
        for (let i = 0; i < famillies.length; i++) {
          let payload = {
            user_id: this.selectedUser.id,
            familly_id: famillies[i].id
          };
          await this.removeFamillyToUser(payload);
        }
      }
    },
    handleClickUser(user) {
      this.selectedUser = user;
      this.selectedPage = null;
    }
  },
  computed: {
    ...mapGetters([
      'loadingFamillysPermissions',
      'checkPermission',
      'getListFamilleConfigFrias',
      'famillysPermissions',
      'errorFamillysPermissions'
    ]),
    computedValue() {
      return function(familly) {
        if (
          this.selectedUser.famillies.filter(item => item.id == familly.id)
            .length
        ) {
          return true;
        }
        return false;
      };
    },
    computedValueCheck() {
      return function(famillies) {
        if (famillies.length == this.getListFamilleConfigFrias.length) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    this.fetchAllFammilleConfig({ blocked: false });
    this.handleFilter();
  }
};
</script>

<style lang="scss" scoped>
.Search-style-component {
  .search-box {
    // position: relative;
    // top: 0;
    // right: 0px;
    // background-color: #842828;
    // padding: 5px;
    // color: #871c1c;
    // border: none;
    // border-radius: 19px;
    // margin-bottom: 30px;s
    position: relative;
    top: 0;
    right: 0px;
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
.visibility-permission-familly {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5c7;
  padding: 10px;
  border-radius: 15px;

  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .custom-list-group {
    overflow-y: auto;
    max-height: calc(100vh - 330px);
    height: calc(100vh - 330px);
  }
  .permission-hearder,
  .permission-body {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    // overflow-y: scroll;
    //     width : 50% ;
  }
  .permission-hearder-sous-interface {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    width: 27%;
    margin-right: 10px;
  }
  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    width: 50%;
    margin-right: 10px;
  }
  .permission-hearder {
    width: 25%;
    margin-right: 10px;
  }
  .body-permission {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    width: 27%;
  }
  .permission-body {
    width: 75%;
    display: flex;

    .permission-select-component {
      margin-bottom: 10px;
    }
    .permission-user,
    .permission-interface {
      width: 37%;
      padding: 5px;
    }
    .permission-autorisation {
      width: 25%;
      padding: 5px;
    }
  }
  .position-btn {
    position: relative;
    text-align-last: center;
    padding-top: 6px;
    .config-coef-btn {
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss">
.visibility-permission-familly {
  // width: 30%;
  /* width */
  .fix-height-slide-user {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 325px);
    padding-right: 9px;
  }

  .fix-height-slide-user::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .fix-height-slide-user::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .fix-height-slide-user::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .fix-height-slide-user::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }

  .description-column {
    width: 76%;
    float: left;
  }
  .info-column {
    width: 10%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }
  .permission-hearder {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 270px);
  }
  .permission-hearder-sous-interface {
    width: 50%;
    margin-right: 10px;
  }

  .permission-hearder-autorisations {
    display: block;
    overflow-y: scroll !important;
    height: 10px;
  }
  .permission-hearder-sous-interface {
    width: 50%;
    margin-right: 10px;
  }
  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
  .check-permission {
    accent-color: #4d4bac;
    height: 20px; /* not needed */
    width: 20px; /* not needed */
  }
}
</style>
